body {
  margin: 0;
}

.App {
  display: flex;
  flex-direction: row;
  height: 105vh;
  font-family: "Montserrat";
  color: #626262;
}

h1 {
  text-transform: uppercase;
  letter-spacing: .25rem;
  font-weight: normal;
}

ul {
  list-style: none;
  padding-left: 1rem;
}

a {
  text-decoration: none;
  color: #626262;
}

.navigation-ul {
  padding: 0 2rem 0 2rem;
  text-align: right;
}

.navigation-ul li {
  font-size: .85rem;
  text-transform: lowercase;
  margin-bottom: .9rem;
  cursor: pointer;
  line-height: 1;
}

.navigation-ul li:hover {
  text-decoration: underline;
}

.content-gutter {
  width: 6vw;
}

.content-container {
  width: 94vw;
  display: flex;
  flex-direction: column;
}

.content-container-body {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.content-container-navigation {
  width: 10vw;
}

.content-container-main {
  width: 84vw;
  margin-top: 14vh;
}

.copyright {
  text-transform: uppercase;
  font-size: .8rem;
  text-align: right;
  margin-right: 7rem;
}

.gallery-container {
  display: flex;
  flex-direction: column;
}

.gallery-img-container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  width: 75vw;
  height: 70vh;
  white-space: nowrap;
}

.gallery-img {
  max-height: 70vh;
  margin-right: 1.5rem;
}

.gallery-text-container p {
  font-size: .85rem;
  line-height: 1.4;
}

#logo {
  height: 13vh;
}

.logo-container {
  display: flex;
  justify-content: flex-end;
}

#contact p {
  font-size: .85rem;
  line-height: 1.6; 
}

.gallery-text-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.gallery-text-bottom {
  width: 35vw;
}

@media only screen and (max-width: 1500px) {
  .gallery-img-container {
    width: 85vw;
  }

  .content-gutter {
    width: 0vw;
  }

  .content-container {
    width: 100vw;
  }

  .content-container-navigation {
    min-width: 170px;
  }
}

.content-container-navigation-mobile {
  display: none;
}

.gallery-container-mobile {
  display: none;
}

.mobile-nav-item {
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
}

@media only screen and (max-width: 1000px) {
  .content-container-main {
    margin: 1rem;
    width: auto;
  }

  .content-container-navigation {
    display: none;
  }
  
  .logo-mobile {
    height: 5vh;
    margin-left: 0.5rem;
  }

  .content-container-body {
    flex-direction: column;
  }

  .content-container-navigation-mobile {
    height: 7vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .gallery-container-mobile {
    display: flex;
  }

  #contact {
    margin: 1rem;
  }

  #contact p {
    font-size: 1rem;
  }

  .gallery-container {
    display: none;
  }

  .gallery-container-mobile {
    display: flex;
    flex-direction: column;
  }

  .gallery-img-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  
  .gallery-img-mobile {
    max-width: 95%;
    margin-bottom: 1rem;
  }

  .gallery-text-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 2rem 1rem 2rem;
  }

  .gallery-text-mobile p {
    margin-top: 0;
  }

  .copyright {
    text-align: center;
    margin: 0;
  }
}

@media only screen and (max-height: 600px) {
  .logo-mobile {
    height: 9vh;
  }

  .content-container-navigation-mobile {
    height: 11vh;
  }
}